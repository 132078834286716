html,
body {
  height: 100%;
}

body {
  background: #fafafa;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

/* ==========================================================================
Developement Ribbon
========================================================================== */
.development {
  position: absolute;
  width: 149px;
  height: 149px;
  top: 0;
  left: 0;
  border: 0;
  z-index: 1000;
  background-image: url('../images/development_ribbon.png');
  background-position: center center;
  background-repeat: no-repeat;
}

/* ==========================================================================
Loading animation
========================================================================== */

.glyphicon-refresh-animate {
  animation: spin 0.7s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* Анимация прогресс-бара (например, в модальном окне экспорта курсов) */
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}

@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}

/* ==========================================================================
Version number in navbar
========================================================================== */

.navbar-version {
  font-size: 10px;
  color: #ccc;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px;
}

.hero-unit h1 {
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
}

.error {
  color: white;
  background-color: red;
}

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url('../images/hipster.png') no-repeat center top;
  background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url('../images/hipster2x.png') no-repeat center top;
    background-size: contain;
  }
}

.hand {
  cursor: pointer;
}

#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

.alert .popover pre {
  font-size: 10px;
}

.voffset {
  margin-top: 2px;
}

.voffset1 {
  margin-top: 5px;
}

.voffset2 {
  margin-top: 10px;
}

.voffset3 {
  margin-top: 15px;
}

.voffset4 {
  margin-top: 30px;
}

.voffset5 {
  margin-top: 40px;
}

.voffset6 {
  margin-top: 60px;
}

.voffset7 {
  margin-top: 80px;
}

.voffset8 {
  margin-top: 100px;
}

.voffset9 {
  margin-top: 150px;
}

.no-padding {
  padding: 0;
}

/* start Password strength bar style */

ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px;
}

.point:last {
  margin: 0 !important;
}

.point {
  background: #ddd;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px;
}

/* end Password strength bar style */
/*Start CSS Login from portal http://spb-w8.tepkom.spb.su/login*/

body .loginbox a {
  color: #00bff3;
  text-decoration: none;
  border-bottom: 1px solid #00bff3;
}

body .loginbox a:hover {
  border-color: transparent;
}

.loginbox {
  width: 660px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 54px;
  background: #3a4d5e;
  border-bottom: 8px solid #f99845;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -210px;
  margin-left: -330px;
  box-shadow: 0 3px 10px -2px #777;
  color: #959595;
  z-index: 100;
}

.loginbox__extra {
  width: 322px;
  height: 45px;
  position: absolute;
  top: 64px;
  right: -12px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 12px;
  background: #59b151;
  border-radius: 4px;
  box-shadow: 0 2px 2px #333;
  color: #fff;
}

.loginbox__extra:before {
  content: '';
  position: absolute;
  bottom: -11px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12px 0 0;
  border-color: #59b151 transparent transparent transparent;
  z-index: -1;
}

.loginbox__logo {
  padding-top: 10px;
  height: 70px;
  background: url('../images/logotype.png') no-repeat 0% 30%;
}

.loginbox__form {
  padding-top: 30px;
}

.loginform__fields {
  display: table;
  width: 100%;
  margin-bottom: 30px;
}

.loginform__fields__field {
  display: table-cell;
  vertical-align: top;
}

.loginform__fields__field:nth-child(2n) {
  text-align: right;
}

.input__field {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 255px;
}

input.input__text {
  background: #ebebeb;
  border: 1px solid #c1c7cf;
  border-radius: 8px;
  padding: 15px;
  font-size: 16px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  color: #333;
  height: auto;
}

input.input__text:hover {
  border-color: #b7bec8 !important;
}

input.input__text:focus {
  border-color: #b7bec8 !important;
  box-shadow: inset 0 0 0 2px #a4adb9 !important;
}

.input__submit {
  border: none;
  background: #00bff3;
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
  padding: 8px 35px;
  cursor: pointer;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  margin-bottom: 20px;
}

.input__submit:hover {
  background: #00b2e2;
}

.loginform__controls {
  display: table;
  width: 100%;
}

.loginform__controls__options {
  display: table-cell;
  vertical-align: top;
  line-height: 150%;
}

.loginform__controls__submit {
  display: table-cell;
  vertical-align: top;
  text-align: right;
}

/*Finish CSS Login from portal http://spb-w8.tepkom.spb.su/login*/

/*Start Grid Table Open Task and Rubric*/
.gridStyle {
  width: 100%;
  min-height: 200px;
}

#openTaskMatrix {
  width: 100%;
}

#openTaskMatrix select,
#rubricMatrix select {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 5px;
}

#openTaskMatrix select.disabled {
  background: #e8eff4;
}

#openTaskMatrix .headCellHoverEnter {
  position: absolute;
  right: -30px;
  z-index: 1;
  background-color: white;
  border: 2px solid gray;
  cursor: text;
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -o-user-select: text;
  user-select: text;
}

#openTaskMatrix .headCellHoverLeave {
  position: relative;
  z-index: 0;
  background-color: inherit;
  border: none;
  cursor: default;
}

#openTaskMatrix .ngHeaderText,
#rubricMatrix .ngHeaderText {
  white-space: normal;
}

/*Finish Grid Table Open Task*/
.topicMainAssignmentsBlock {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.topicMainAssignmentsBlock a {
  color: inherit;
  text-decoration: none;
  font-size: 16px;
}

.topicSummaryForm {
  margin-bottom: 20px;
}

.topicSummaryForm .checkbox {
  margin-right: 20px;
}

.linkRows tr:hover {
  background-color: #70b7cb;
}

.linkRows tr:hover td {
  background-color: #70b7cb;
}

.linkCells:hover {
  background-color: #70b7cb;
}

.filling {
  width: 100%;
  height: 100%;
}

.autoscale_td {
  height: 1px;
}

.lms-row {
  margin-bottom: 15px;
}

/* Files */
ul.current-path-items {
  list-style: none;
  overflow: scroll;
  width: auto;
  height: 450px;
}

ul.current-path-items li {
  display: inline-block;
  position: relative;
  margin: 5px 10px;
  width: 80px;
  padding: 5px;
  overflow: hidden;
  word-wrap: break-word;
  vertical-align: top;
}

ul.current-path-items li.path-file {
  display: flex;
  justify-content: space-between;
}

ul.current-path-items li.btn-file-manager span {
  display: inline-block;
  font-size: 12px;
}

.path-file-info {
  display: inline-block;
  max-width: 400px;
}

ul.current-path-items li span {
  font-size: 14px;
  word-wrap: break-word;
}

ul.current-path-items li:hover {
  cursor: pointer;
}

ul.current-path-items li.selected {
  border: 1px solid grey;
}

.current-path-toolbar {
  background-color: white;
  padding: 5px 10px;
  position: relative;
  border: 1px solid #e3e3e3;
  /* height: auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.edit_content_textarea {
  width: 100%;
  height: 270px;
}

.add-test-button {
  margin: -10px 0 10px 10px;
}

ul li.path-item {
  width: 550px;
  text-align: left;
}

.readable_size {
  font-size: 12px;
}

.file-name {
  padding-left: 10px;
  font-size: 12px;
}

.current-path-toolbar > * {
  display: inline-block;
}

.current-path-block {
  display: -ms-flexbox;
  -ms-flex-pack: center;
  display: flex;
  align-items: center;
}

.current-path-val {
  margin-left: 10px;
}

.clipboard-link {
  cursor: pointer;
}

/* End Files */

.bold {
  font-weight: bold;
}

.table-controls th {
  cursor: pointer;
}

.table-controls th:hover {
  color: #337ab7;
}

.lms-control {
  margin: 20px 0;
}

#editFileEditor .modal-dialog {
  width: 100%;
}

#editFileEditor .modal-content {
  height: 800px;
}

#editFileEditor .modal-content textarea {
  height: 600px;
}

.org-structure-tree {
  font-size: 16px;
}

.org-structure-tree .indicator {
  color: #337ab7;
}

.org-structure-tree .btn-add,
.org-structure-region-btn {
  margin: 5px 0 5px 20px;
}

.lms-user-import .panel-title > a {
  outline: none;
}

.lms-user-import .panel-title > a:focus {
  text-decoration: none;
}

.lms-user-import .btn-next {
  margin-top: 20px;
}

.lms-user-import .text-muted {
  color: inherit;
  cursor: default;
}

.lms-user-import .counters {
  max-width: 400px;
}

.image-viewer .modal-dialog {
  width: auto;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.displayed {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.lesson-page-edit-url {
  width: 80%;
  margin-right: 20px;
}

#welcome-logo {
  background: url('../images/logo.jpg') center center no-repeat;
  width: 300px;
  height: 100px;
  margin: 0 auto;
}

.edit-lesson-page .modal-dialog {
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  height: 70%;
  max-height: 70%;
}

.edit-lesson-page .modal-dialog .modal-body {
  height: 80%;
}

.edit-lesson-page .modal-dialog .modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.edit-lesson-page .modal-dialog textarea {
  height: 90%;
  flex: 1;
}

.edit-lesson-page .modal-dialog .modal-footer {
  height: auto;
  display: block;
  min-height: 60px;
  position: relative;
  max-height: 70%;
  padding: 10px;
}
.edit-lesson-page .modal-dialog .modal-footer > .btn {
  margin-top: 5px;
}

.form-group input[required].ng-valid-required {
  background-color: rgba(44, 187, 41, 0.36);
}

.form-group input[required].ng-invalid-required {
  background-color: rgba(187, 41, 41, 0.36);
}

.form-group input[required].ng-invalid-exists {
  background-color: rgba(187, 41, 41, 0.36);
}

#scroll-to-top {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 50px;
  width: 50px;
  background-image: url('../images/up.png');
  background-color: rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
}

#scroll-to-top:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.table-header-sort {
  cursor: pointer;
}

.course-list-filter {
  display: inline-block;
  margin-left: 15px;
}

.course-list-filter > select {
  max-width: 300px;
}

/* styles for cruduser table */
@media (min-width: 1200px) {
  .cruduser-list-buttons {
    min-width: 400px;
  }
}

@media (max-width: 1200px) {
  .cruduser-list-buttons {
    min-width: 205px;
  }
  .cruduser-list-buttons .label {
    display: none;
  }
}

@media (max-width: 990px) {
  .cruduser-list-buttons {
    min-width: 100px;
  }
  .cruduser-list-buttons .label {
    display: none;
  }
}

/*=================tinymce===================*/
i.mce-i-pi:before {
  content: 'π';
}
/*===============================================*/
table {
  font-size: 14px;
}

.nowrap {
  white-space: nowrap;
}

/* .adapted-program */
.adapted-program {
  width: 34px;
  height: 34px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #ccc;
}
.adapted-program--inline-block {
  display: inline-block;
  vertical-align: middle;
}
.adapted-program__item {
  width: 34px;
  height: 34px;
  position: absolute;
  left: -40px;
  top: 0;
  text-align: left;
  text-indent: -99999px;
  transition: left 0.1s ease-out;
}
.adapted-program__item--selected {
  left: 0;
}
.adapted-program__item--C_AQUA {
  background: aqua;
}
.adapted-program__item--C_BLUE {
  background: blue;
}
.adapted-program__item--C_YELLOW {
  background: yellow;
}
.adapted-program__item--C_ORANGE {
  background: orangered;
}
.adapted-program__item--C_RED {
  background: red;
}
.adapted-program__item--C_GREEN {
 background: green;
}
.adapted-program__item--C_PURPLE {
    background: purple;
}
.adapted-program__item--C_GREY {
    background: grey;
}
/* /.adapted-program */

.mytable {
    max-width: 100%;
    margin-bottom: 10px;
    /*background-image:url("../images/shelf_bg.jpg");
    */

}

.mytd {
    width: 130px;
    height: 160px;
    padding-left: 10px;
    vertical-align: top;

}

.highdiv {
    height: 70px;
    background: white;

}
.imagebook{
    height: 70px;
    background:#eee;
}
.linediv{
    background-image:url("/assets/images/line.png");
    height: 1px;
}
.bookfoter{
    background: #eee;
    height: 20px;
}
.bookboard{
    background-image:url("/assets/images/board_bg.png");
    background-repeat: repeat-x;
    height: 70px;

}
.booklink{
    text-decoration: none;
}
a:hover{
    text-decoration: none;
}

.quiz input[type=text] {
  width: 10%;
  min-width: 80px !important;
  max-width: 600px !important;
}

.quiz .feedback {
  padding-left: 15px;
  padding-right: 15px;
}

.quiz .correct {
  color: #008000;
}

.quiz .incorrect {
  color: #ff0000;
}

.multiple_choice_question select {
  min-width: 80px;
  max-width: 600px;
}

.matching_question select {
  min-width: 80px;
  max-width: 600px;
}

.underline {
  text-decoration: underline;
}

.attempt_column {
  width: 5% !important;
}

.quiz_label {
  font-weight: normal;
  width: 100%;
  padding-top: 10px;
}

textarea {
  width: 95%;
  float: right;
  resize: vertical;
}

input[type="checkbox"],
input[type="radio"] {
  float: left;
}

.messages {
    margin: 0 0 15px;
}

.message-block {
    min-height: 50px;
    max-height: 500px;
    overflow-y: auto;
    margin: 0 0 10px;
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
}

form[name="messageForm"] {
    margin: 0 0 15px;
}

.message-text {
    margin: 0 0 10px;
    resize: vertical;
}

.message-file-drop-area {
    color: #3c763d;
    background: #dff0d8;
    border: 1px solid #d6e9c6;
    border-radius: 4px;
    padding: 20px 20px;
    transition: all 0.3s ease-in-out 0s
}

.message-file-dragover {
    padding: 40px 20px;
    border: 3px solid #81ad5c;
}

.message-file-list {
    list-style: none;
}

.message-avatar-container {
    display: table-cell;
    padding-right: 10px;
}

div.message-avatar {
    width: 64px;
    height: 64px;
}

img.message-avatar {
    max-height: 100%;
    max-width: 100%;
}
/* Bootstrap theme */

/* Helper class to show styles when focus */
.btn-default-focus {
    color: #333;
    background-color: #EBEBEB;
    border-color: #ADADAD;
    text-decoration: none;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}


/* Fix Bootstrap dropdown position when inside a input-group */
.input-group > .ui-select-bootstrap.dropdown {
    /* Instead of relative */
    position: static;
}

.input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
    border-radius: 4px; /* FIXME hardcoded value :-/ */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ui-select-bootstrap > .ui-select-match {
    /* Instead of center because of .btn */
    text-align: left;
}

.ui-select-bootstrap > .ui-select-match > .caret {
    position: absolute;
    top: 45%;
    right: 15px;
}

/* See Scrollable Menu with Bootstrap 3 http://stackoverflow.com/questions/19227496 */
.ui-select-bootstrap > .ui-select-choices {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

.ui-select-multiple.ui-select-bootstrap {
    height: auto;
    padding: .3em;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    background-color: transparent !important; /* To prevent double background when disabled */
    border: none;
    outline: none;
    height: 1.666666em;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    font-size: 1.6em;
    line-height: 0.75;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item{
    outline: 0;
}

.ui-select-bootstrap .ui-select-choices-row>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.ui-select-bootstrap .ui-select-choices-row>a:hover, .ui-select-bootstrap .ui-select-choices-row>a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
}

.ui-select-bootstrap .ui-select-choices-row.active>a {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #428bca;
}

.ui-select-bootstrap .ui-select-choices-row.disabled>a,
.ui-select-bootstrap .ui-select-choices-row.active.disabled>a {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
}

/* fix hide/show angular animation */
.ui-select-match.ng-hide-add,
.ui-select-search.ng-hide-add {
    display: none !important;
}

/* Mark invalid Bootstrap */
.ui-select-bootstrap.ng-dirty.ng-invalid > button.btn.ui-select-match {
    border-color: #D44950;
}

.form-control[type=file] {
  height: auto;
}
